import * as React from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import * as hooks from '@owl-nest/hooks'
import * as layout from '@owl-nest/layout'
import * as shadow from '@owl-nest/shadow'
import * as entities from '@ulule/entities'
import { t, tc } from '@owl-nest/localize'
import * as messages from '@owl-nest/messages'
import * as header from '@ulule/header'

import * as plume from '@ulule/owl-kit-components/next'

import { getCountry, getCurrencyCode, getCurrentLocale } from './router'
import * as style from './style'
import { Seo } from './Seo'

import { Boutique } from './sections/Boutique'
import { Categories } from './sections/Categories'
import { Channels } from './sections/Channels'
import { HeroHeader } from './sections/HeroHeader'
import { LeadMagnet } from './sections/LeadMagnet'
import { Partners } from './sections/Partners'
import { Projects } from './sections/Projects'
import { Training } from './sections/Training'

const Create = dynamic(() => import('./sections/Create').then((module) => module.Create), {
  ssr: true,
  loading: LoadingSection,
})
const Story = dynamic(() => import('./sections/Story').then((module) => module.Story), {
  ssr: true,
  loading: LoadingSection,
})
const Services = dynamic(() => import('./sections/Services').then((module) => module.Services), {
  ssr: true,
  loading: LoadingSection,
})
const Newsletter = dynamic(() => import('./sections/Newsletter').then((module) => module.Newsletter), {
  ssr: true,
  loading: LoadingSection,
})
const Waves = dynamic(() => import('@ulule/layout').then((module) => module.backgrounds.Waves), {
  ssr: true,
  loading: LoadingSection,
})

export type AppProps = {
  homepage: entities.homepage.Homepage
}

export function App({ homepage }: AppProps): React.ReactElement {
  const router = hooks.useNextRouter()
  const currentLocale = getCurrentLocale(router)
  const currencyCode = getCurrencyCode(router)
  const targetCurrency = currencyCode ?? 'EUR'

  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: '200px' })

  return (
    <shadow.TrackingProvider
      gtmContainerId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER || ''}
      pageCategory={shadow.CATEGORY.HOMEPAGE}
      siteCategory={shadow.SITE.ULULE}
      userLocale={currentLocale}
    >
      <Seo seo={homepage.seo} />

      <messages.MessagesProvider>
        <messages.ToastDisplayer contexts={['delete']} position="top" />

        <header.Header lang={currentLocale} theme={{ colors: { primary: plume.COLORS.PRIMARY_GREY_000 } }} />

        <Main>
          <HeroHeader successStories={homepage.hero.successStories} />
          <Projects categories={homepage.categories} featuredOperation={homepage.featuredOperation} />
          {homepage.categories.length > 0 && <Categories categories={homepage.categories} />}
          <Channels channels={homepage.channels} />
          <LeadMagnet leadMagnet={homepage.leadMagnet} />
          <Training testimonial={homepage.testimonial} training={homepage.training} />
          <Boutique boutique={homepage.boutique} />
          <Partners partners={homepage.partners} />
          <div ref={ref} />
          {inView && (
            <>
              <Story story={homepage.story} />
              <Services services={homepage.services} />
              <Create ownerTestimonials={homepage.ownerTestimonials} />
              <Newsletter />
              <Waves />
            </>
          )}
        </Main>
      </messages.MessagesProvider>

      <layout.Footer
        withRedirectParam
        controlledCurrency={targetCurrency}
        lang={currentLocale}
        navSections={layout.getNavSectionList(currentLocale)}
      />
    </shadow.TrackingProvider>
  )
}

function Main({ children }: { children: React.ReactNode }): React.ReactElement {
  useUserDeleteLogoutMessage()

  return <style.Main>{children}</style.Main>
}

function useUserDeleteLogoutMessage(): void {
  const messenger = messages.useMessage()

  React.useEffect(() => {
    if (localStorage.getItem('ul_delete_user_logout')) {
      messenger.confirm({
        message: tc(
          "Your account has now been deleted. If you'd like to contact our support team, don't hesitate to send an email to [contact: support@ulule.com]. See you soon!",
          { contact: <plume.Link href="mailto:support@ulule.com" /> },
        ),
        onPrimaryAction: () => {
          // TODO: Handle per message, not context
          messenger.softDelete('delete')
        },
        primaryAction: t('Close'),
        type: 'toast',
        context: 'delete',
        onClose: () => {
          //
        },
        forceClose: true,
      })
      localStorage.removeItem('ul_delete_user_logout')
    }
  }, [])
}

function LoadingSection(): React.ReactElement {
  return (
    <LoadingSectionWrapper>
      <plume.Spinner />
    </LoadingSectionWrapper>
  )
}

const LoadingSectionWrapper = styled.section`
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-content: center;
`
