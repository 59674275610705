import * as React from 'react'
import styled from 'styled-components'

import { useTransition } from '../behaviours'
import * as glyphs from '../icons/glyphs'
import * as notification from '../styles/notification'

const toastGlyphs: {
  [s in notification.ToastTypeWithoutNeutral]: glyphs.IconElement
} = {
  alert: glyphs.fill.Alert,
  error: glyphs.fill.TimesCircle,
  info: glyphs.fill.Info,
  success: glyphs.fill.Success,
}

export type ToastProps = {
  children: React.ReactNode
  closed: boolean
  className?: string
  onClose?: () => void
  onPrimaryAction?: () => void
  onSecondaryAction?: () => void
  position?: 'bottom' | 'top'
  primaryAction?: React.ReactNode
  secondaryAction?: React.ReactNode
  type: notification.ToastType
}

function ToastComponent({
  children,
  closed,
  className,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  position = 'bottom',
  primaryAction,
  secondaryAction,
  type,
}: ToastProps): React.ReactElement<ToastProps> {
  const Glyph = type !== 'neutral' ? toastGlyphs[type] : null
  const { step, unmounted, onTransitionEnd } = useTransition(!closed)

  React.useEffect(() => {
    if (closed && onClose) {
      onClose()
    }
  }, [unmounted])

  return (
    <notification.ToastBackground className={className} step={step} position={position}>
      <notification.ToastContainer type={type} onTransitionEnd={onTransitionEnd}>
        <notification.ToastContent>
          {Glyph && <Glyph size={18} />}
          <span>{children}</span>
        </notification.ToastContent>

        <notification.ToastActions>
          {primaryAction && (
            <notification.ToastPrimaryAction
              onClick={() => {
                onPrimaryAction && onPrimaryAction()
              }}
            >
              {primaryAction}
            </notification.ToastPrimaryAction>
          )}
          {secondaryAction && (
            <notification.ToastAction
              onClick={() => {
                onSecondaryAction && onSecondaryAction()
              }}
            >
              {secondaryAction}
            </notification.ToastAction>
          )}
        </notification.ToastActions>
      </notification.ToastContainer>
    </notification.ToastBackground>
  )
}

export const Toast = styled(ToastComponent)<ToastProps>``
Toast.displayName = 'Toast'
